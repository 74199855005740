#root {
    padding: 32px;
    /*position: absolute;*/
    /*height: 100%;*/
    /*width: 100%;*/
    /*display: flex;*/
    /*flex-direction: column;*/
    /*justify-content: center;*/
    /*align-items: center;*/
}
